<template>
  <b-modal
    v-model="onControl"
    :title="'Commission Changes: ' + descriptionCommission"
    title-tag="h3"
    modal-class="modal-primary"
    size="caro"
    :hide-footer="viewFromCrmTracking"
    :no-close-on-backdrop="!viewFromCrmTracking"
    @hidden="close"
  >
    <h4 class="text-primary">
      Before
    </h4>
    <!--    <b-card-group-->
    <!--      class="d-flex flex-wrap justify-content-between"-->
    <!--      deck-->
    <!--    >-->
    <b-row
      cols="12"
      cols-xl="12"
      cols-lg="6"
      cols-sm="4"
      cols-md="2"
    >
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          class="text-center"
        >
          <template #header>
            <span class="font-small-3">Captured amount</span>
          </template>
          <b-card-text> $ {{ commissionData.amount_captured | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          class="text-center"
        >
          <template #header>
            <span class="font-small-3">Seller Beginner (PE)</span>
          </template>
          <b-card-text> $ {{ commissionData.seller_PE_bg | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Seller Junior (PE)"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.seller_PE_jr | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Seller Junior (US)"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.seller_US_jr | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Seller Senior (PE)"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.seller_PE_sr | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Seller Senior (US)"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.seller_US_sr | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Seller Master (PE)"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.seller_PE_mr | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Catcher beginner"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.catcher_bg | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Catcher Junior"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.catcher_jr | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Catcher Senior"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.catcher_sr | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Recovery Beginner"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.recovery_bg | currency }}</b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="2"
        md="6"
        xl="1"
      >
        <b-card
          header="Recovery Senior"
          header-class="font-small-3"
          class="text-center"
        >
          <b-card-text> $ {{ commissionData.recovery_sr | currency }}</b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <!--    </b-card-group>-->
    <div class="d-flex justify-content-between">
      <h4 class="text-primary">
        After
      </h4>
      <!--      <div>-->
      <!--        <b-form-checkbox v-model="checkAll">-->
      <!--          <span class="font-small-3">Check All</span>-->
      <!--        </b-form-checkbox>-->
      <!--      </div>-->
    </div>
    <validation-observer
      v-if="isCeo"
      ref="form"
    >
      <b-row
        cols="12"
        cols-xl="12"
        cols-lg="6"
        cols-sm="4"
        cols-md="2"
      >
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Captured amount"
            header-class="font-small-3"
            :class="editedColumnsData.amount_captured_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.amount_captured | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="capturedAmountCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"
                  v-model="dataCheckbox.capturedAmountCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.amount_captured_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Seller Beginner (PE)"
            header-class="font-small-3"
            :class="editedColumnsData.seller_PE_bg_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.seller_PE_bg | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="sellerBeginnerAmountPeCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"
                  v-model="dataCheckbox.sellerBeginnerAmountPeCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.seller_PE_bg_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Seller Junior (PE)"
            header-class="font-small-3"
            :class="editedColumnsData.seller_PE_jr_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.seller_PE_jr | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="sellerJuniorAmountPeCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"

                  v-model="dataCheckbox.sellerJuniorAmountPeCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.seller_PE_jr_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Seller Junior (US)"
            header-class="font-small-3"
            :class="editedColumnsData.seller_US_jr_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.seller_US_jr | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="sellerJuniorAmountUsCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"

                  v-model="dataCheckbox.sellerJuniorAmountUsCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.seller_US_jr_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Seller Senior (PE)"
            header-class="font-small-3"
            :class="editedColumnsData.seller_PE_sr_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.seller_PE_sr | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="sellerSeniorAmountPeCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"

                  v-model="dataCheckbox.sellerSeniorAmountPeCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.seller_PE_sr_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Seller Senior (US)"
            header-class="font-small-3"
            :class="editedColumnsData.seller_US_sr_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.seller_US_sr | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="sellerSeniorAmountUsCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"
                  v-model="dataCheckbox.sellerSeniorAmountUsCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.seller_US_sr_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Seller Master (PE)"
            header-class="font-small-3"
            :class="editedColumnsData.seller_PE_mr_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.seller_PE_mr | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="sellerMasterAmountPeCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"
                  v-model="dataCheckbox.sellerMasterAmountPeCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.seller_PE_mr_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Catcher beginner"
            header-class="font-small-3"
            :class="editedColumnsData.catcher_bg_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.catcher_bg | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="catcherBeginnerAmountCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"
                  v-model="dataCheckbox.catcherBeginnerAmountCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.catcher_bg_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Catcher Junior"
            header-class="font-small-3"
            :class="editedColumnsData.catcher_jr_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.catcher_jr | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="catcherJuniorAmountCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"
                  v-model="dataCheckbox.catcherJuniorAmountCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.catcher_jr_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Catcher Senior"
            header-class="font-small-3"
            :class="editedColumnsData.catcher_sr_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.catcher_sr | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="catcherSeniorAmountCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"
                  v-model="dataCheckbox.catcherSeniorAmountCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.catcher_sr_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Recovery Beginner"
            header-class="font-small-3"
            :class="editedColumnsData.recovery_bg_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.recovery_bg | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="recoveryBeginnerAmountCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"
                  v-model="dataCheckbox.recoveryBeginnerAmountCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.recovery_bg_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
        <b-col
          lg="2"
          md="6"
          xl="1"
        >
          <b-card
            class="text-center"
            header="Recovery Senior"
            header-class="font-small-3"
            :class="editedColumnsData.recovery_sr_cond === 0 ? 'card-br' : ''"
          >
            <b-card-text> $ {{ commissionTrackingData.recovery_sr | currency }}</b-card-text>
            <div class="d-flex justify-content-center">
              <validation-provider
                v-slot="{ errors }"
                name="recoverySeniorAmountCheck"
                rules="truthy"
              >
                <b-form-checkbox
                  v-if="!viewFromCrmTracking"
                  v-model="dataCheckbox.recoverySeniorAmountCheck"
                  :state="errors[0] ? false : null"
                  :disabled="editedColumnsData.recovery_sr_cond === 1"
                />
              </validation-provider>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>

    <template #modal-footer>
      <b-button
        variant="danger"
        @click="approveDisapprove(3)"
      >
        Reject
      </b-button>
      <b-button
        variant="primary"
        @click="approveDisapprove(2)"
      >
        Approve
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import CeSetCommissionsService from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'

export default {
  props: {
    editedCommissionId: {
      type: Number,
      default: null,
    },
    commissionId: {
      type: Number,
      default: null,
    },
    viewFromCrmTracking: {
      type: Boolean,
      default: false,
    },
    descriptionCommission: {
      type: String,
      default: '',
    },
    commissionItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      onControl: false,
      commissionTrackingData: {},
      commissionData: {},
      dataCheckbox: {
        capturedAmountCheck: false,
        sellerBeginnerAmountPeCheck: false,
        sellerJuniorAmountPeCheck: false,
        sellerJuniorAmountUsCheck: false,
        sellerSeniorAmountPeCheck: false,
        sellerSeniorAmountUsCheck: false,
        sellerMasterAmountPeCheck: false,
        catcherBeginnerAmountCheck: false,
        catcherJuniorAmountCheck: false,
        catcherSeniorAmountCheck: false,
        recoveryBeginnerAmountCheck: false,
        recoverySeniorAmountCheck: false,
      },
      editedColumnsData: {},
      checkAll: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  watch: {
    checkAll() {
      if (this.checkAll) {
        this.dataCheckbox = {
          capturedAmountCheck: true,
          sellerBeginnerAmountPeCheck: true,
          sellerJuniorAmountPeCheck: true,
          sellerJuniorAmountUsCheck: true,
          sellerSeniorAmountPeCheck: true,
          sellerSeniorAmountUsCheck: true,
          sellerMasterAmountPeCheck: true,
          catcherBeginnerAmountCheck: true,
          catcherJuniorAmountCheck: true,
          catcherSeniorAmountCheck: true,
          recoveryBeginnerAmountCheck: true,
          recoverySeniorAmountCheck: true,
        }
      } else {
        this.dataCheckbox = {
          capturedAmountCheck: false,
          sellerBeginnerAmountPeCheck: false,
          sellerJuniorAmountPeCheck: false,
          sellerJuniorAmountUsCheck: false,
          sellerSeniorAmountPeCheck: false,
          sellerSeniorAmountUsCheck: false,
          sellerMasterAmountPeCheck: false,
          catcherBeginnerAmountCheck: false,
          catcherJuniorAmountCheck: false,
          catcherSeniorAmountCheck: false,
          recoveryBeginnerAmountCheck: false,
          recoverySeniorAmountCheck: false,
        }
      }
    },
  },
  async created() {
    this.onControl = true
    await this.setUpData()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async setUpData() {
      const data = await CeSetCommissionsService.getGeneralCommissionTrackingById({
        id: this.editedCommissionId,
      })
      if (data.status === 200) {
        this.commissionTrackingData = { ...data.data[0] }
      }
      const data2 = await CeSetCommissionsService.getCommissionById({
        id: this.editedCommissionId,
      })
      if (data2.status === 200) {
        this.commissionData = { ...data2.data[0] }
      }
      const data3 = await CeSetCommissionsService.getEditedColumnsCommissions({
        id: this.editedCommissionId,
      })
      if (data2.status === 200) {
        this.editedColumnsData = { ...data3.data[0] }
      }
      this.setUpCheckboxes()
    },
    setUpCheckboxes() {
      this.dataCheckbox = {
        capturedAmountCheck: this.editedColumnsData.amount_captured_cond === 1,
        sellerBeginnerAmountPeCheck: this.editedColumnsData.seller_PE_bg_cond === 1,
        sellerJuniorAmountPeCheck: this.editedColumnsData.seller_PE_jr_cond === 1,
        sellerJuniorAmountUsCheck: this.editedColumnsData.seller_US_jr_cond === 1,
        sellerSeniorAmountPeCheck: this.editedColumnsData.seller_PE_sr_cond === 1,
        sellerSeniorAmountUsCheck: this.editedColumnsData.seller_US_sr_cond === 1,
        sellerMasterAmountPeCheck: this.editedColumnsData.seller_PE_mr_cond === 1,
        catcherBeginnerAmountCheck: this.editedColumnsData.catcher_bg_cond === 1,
        catcherJuniorAmountCheck: this.editedColumnsData.catcher_jr_cond === 1,
        catcherSeniorAmountCheck: this.editedColumnsData.catcher_sr_cond === 1,
        recoveryBeginnerAmountCheck: this.editedColumnsData.recovery_bg_cond === 1,
        recoverySeniorAmountCheck: this.editedColumnsData.recovery_sr_cond === 1,
      }
    },
    async approveDisapprove(type) {
      let validation = true
      if (type === 2) {
        validation = await this.$refs.form.validate()
      }
      if (validation) {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CeSetCommissionsService.approveDisapproveSalesCommission({
            status: type,
            commission_tracking_id: this.editedCommissionId,
            commission_id: this.commissionId,
            user_id: this.currentUser.user_id,
            commission_description: this.descriptionCommission,
            user_name: this.currentUser.fullName,
            to_id: this.commissionItem.created_by_id,
            program_name: this.commissionItem.value,
          })
          if (data.status === 200) {
            this.showSuccessSwal()
            this.close()
            this.$emit('refresh')
          }
        }
      }
    },
  },
}
</script>

<style>
/*.dark-layout .card .bri{*/
/*  background: #354035 !important;*/
/*}*/
.card-br{
  /*background: #adcee7 !important;*/
  background: #c5e2e7 !important;
}
.dark-layout .card-br{
  background: #3f5a70 !important;
}
</style>
